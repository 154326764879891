<template>
  <div
    class="content-component"
    @click="handleClearSelection"
    style="width: 100%;"
  >
    <el-button
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      @click.stop="createContentItem"
    >
      {{ __("Create Formtype") }}
    </el-button>
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :show-content-view-switcher="true"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              :class="contentViewClasses"
              v-if="formTypes.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
            >
              <content-list-item
                :content-view-type="contentViewType"
                v-for="(formType, index) in formTypes"
                :item-index="index"
                :key="'_content_form_type_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [formType[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="formType.is_archived === 1 ? false : true"
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-custom-form.svg"
                :card-text="formType.form_type_name"
                :content-list-item="formType"
                :fields-to-display="displayFields"
                @delete="
                  handleDelete(formType, 'FormType', formType.form_type_id)
                "
                @select="handleSelect(index, formType)"
                @edit="handleEdit(index, formType)"
                @checked-content="handleSelectMultiple($event)(formType)"
                @toggle-select-all="
                  handleToggleSelectAll(formTypes, ...arguments)
                "
                :is-all-selected="isAllSelected"
                :total-items-selected="selectedItems.length"
              ></content-list-item>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              {{ __("No Custom Forms") }}
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentListItem from "@/views/build/content/mixins/ContentListItem";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";

export default {
  name: "CustomFormTypesList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentListItem
  },
  selectedItemIndex: {
    required: false,
    default: null
  },
  data() {
    return {
      additionalValidateRoute: "custom-forms",
      sortByColumns: ["form_type_name", "form_type_id"],
      isContent: true,
      viewMode: "allow_read",
      displayFields: [
        {
          field_name: "form_type_name",
          display_name: __("Name")
        },
        {
          field_name: "form_type_description",
          display_name: __("Description")
        }
      ]
    };
  },

  computed: {
    ...mapState("formTypes", {
      formTypes: state => state.formtypes,
      loading: state => state.loading
    }),
    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    }
  },

  methods: {
    ...mapActions("formTypes", {
      contentAPI: "getFormTypes"
    })
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: #4db3f6 !default;
$content-theme-hover-color: dodgerblue !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";

.advancedBtn {
  margin-left: 20px;
  height: 44px;
  width: 30px;
  border-color: #f5f5f8;
  color: #a0a8b5;
  &:hover {
    background-color: transparent;
    border-color: $content-theme-color;
    ::v-deep svg {
      fill: $content-theme-hover-color;
      path {
        fill: $content-theme-hover-color;
      }
    }
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}

.custom-icon {
  font-size: 1.5rem;
  color: #a0a8b5;
  padding: 0 0 30px 20px;
}

.archived {
  color: $content-theme-hover-color;
}

.custom-header-text {
  color: #a0a8b5;
  font-size: 17px;
}
</style>

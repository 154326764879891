<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapActions, mapState } from "vuex";

export default {
  name: "CustomFormTypesIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedItemType: null,
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("formTypes", {
      contents: state => state.formtypes
    }),
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  methods: {
    ...mapActions("dataStores", {
      getDataStores: "getDataStores"
    }),
    initContent() {
      return {
        form_type_name: "",
        form_type_description: "",
        is_standard: false,
        ac_id: "",
        data_store_id: "",
        dtmf: "",
        grammar: "",
        is_confusable: false,
        confusable: "",
        is_archived: 0,
        speech_complete_timeout: 1000,
        speech_incomplete_timeout: 20000,
        no_input_timeout: 5000,
        speech_start_timeout: 50,
        inter_result_timeout: 0,
        barge_in_sensitivity: 0.5,
        auto_punctuation: false,
        profanity_filter: false,
        single_utterance: true
      };
    }
  },

  mounted() {
    this.contentType = "FormType";
    this.primaryKey = "form_type_id";
  },
  watch: {
    selectedAccountId: {
      immediate: true,
      handler: function(val) {
        if (val !== "all") {
          this.getDataStores({ fetch_all: 1, is_log: 0 });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="multipleItemsSelectedCount" class="side-panel-selected-count">
      <p>
        Selected
        <el-tag>
          <strong>{{ multipleItemsSelectedCount }}</strong>
        </el-tag>
        items.
      </p>
    </div>
    <div v-else class="side-panel-content-info">
      <div class="field-group">
        <div class="titles">{{ __("Name") }}</div>
        <div class="details">{{ contentForm.form_type_name }}</div>
      </div>
      <div class="field-group">
        <div class="titles">{{ __("Description") }}</div>
        <div class="details">{{ contentForm.form_type_description }}</div>
      </div>
    </div>

    <div style="margin-top: 20px;" class="side-panel-action-list">
      <el-dropdown
        @command="command => handleAction(command)"
        placement="bottom-start"
      >
        <el-button plain class="btn-plain">{{ __("Actions") }}</el-button>
        <el-dropdown-menu slot="dropdown" style="width: 200px;">
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount && !isArchivedStatus"
            :disabled="!can('content.custom-forms.write')"
            command="edit"
          >
            <span>{{ __("Edit") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            command="move"
            v-show="!isArchivedStatus"
            :disabled="!can('content.custom-forms.write')"
          >
            <span>{{ __("Move to folder") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="check_in_use"
            :disabled="!!contentFormInfo.is_refreshing"
          >
            <span>{{ __("Check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="refresh"
          >
            <span>{{ __("Refresh check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount && isArchivedStatus"
            :disabled="!can('content.custom-forms.write')"
            command="restore"
          >
            <span>{{ __("Restore") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            :disabled="!can('content.custom-forms.write')"
            command="delete"
          >
            <span>{{ __("Delete") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
      :modal="false"
    >
      <el-scrollbar :native="false">
        <div class="max-vh">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            :disabled="isArchivedStatus"
          >
            <el-row type="flex" style="padding-top: 20px">
              <el-col :span="6"></el-col>
              <el-col :span="18">
                <el-row type="flex">
                  <h2
                    class="custom-formtype-icon inf-icon inf-icon-custom-form"
                  ></h2>
                  <page-header
                    :title="__('Formtype')"
                    :content-id="this.id"
                    style="margin-top: 33px;"
                  />
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="6"></el-col>
              <el-col :span="9">
                <el-form-item prop="form_type_name" :label="__('Name')">
                  <el-input v-model="contentForm.form_type_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-tabs
                  v-model="activeTab"
                  class="tabs"
                  style="font-size: 1.2em;"
                >
                  <el-tab-pane :label="__('Form')" name="form">
                    <!--            <el-row type="flex">-->
                    <!--              <el-col :span="6"></el-col>-->
                    <!--              <el-col :span="9">-->
                    <el-form-item
                      prop="form_type_description"
                      :label="__('Description')"
                    >
                      <el-input
                        v-model="contentForm.form_type_description"
                      ></el-input>
                    </el-form-item>
                    <!--              </el-col>-->
                    <!--            </el-row>-->
                    <!--            <el-row type="flex">-->
                    <!--              <el-col :span="6"></el-col>-->
                    <!--              <el-col :span="9">-->
                    <el-form-item prop="data_store_id" :label="__('temp')">
                      <span slot="label" style="margin-right: 5px;">{{
                        __("Datastore")
                      }}</span>
                      <!--                  <router-link
                    :to="{ name: 'content', params: { id: '_id' } }"
                    tag="span"
                    slot="label"
                  >
                    <span
                      style="font-size: 12px;border-radius: 3px; font-weight: normal;"
                    >
                      (Select existing datastore or
                    </span>
                    <el-button
                      type="text"
                      size="mini"
                      style="color: var(&#45;&#45;theme-color);"
                      @click="callDataStore"
                    >
                      Create new Datastore <i class="el-icon-edit"
                    /></el-button>
                    <span
                      style=" font-size: 12px;border-radius: 3px; font-weight: normal;"
                      >)</span
                    >
                  </router-link>-->
                      <el-select
                        v-model="contentForm.data_store_id"
                        style="width: 100%;"
                        @change="onDataStoreChange"
                        :no-data-text="noDataTextDS"
                        :loading="dataStoresLoading"
                        :placeholder="placeHolderDS"
                        default-first-option
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="(item, index) in dataStores"
                          :label="item.display_name"
                          :value="item.data_store_id"
                          :key="index"
                        />
                      </el-select>
                    </el-form-item>
                    <!--              </el-col>-->
                    <!--            </el-row>-->
                    <!--            <el-row type="flex">-->
                    <!--              <el-col :span="6"></el-col>-->
                    <!--              <el-col :span="9">-->
                    <el-form-item prop="dtmf" label="temp">
                      <span
                        slot="label"
                        style="margin-right: 5px; padding-bottom: 0px;"
                        >{{ __("Map DTMF") }}</span
                      >
                      <span>
                        {{
                          __("Column mapped to DTMF must be an integer type")
                        }}
                      </span>

                      <el-select
                        v-model="contentForm.dtmf"
                        style="width: 100%;"
                        :no-data-text="noIntegerTextDSColumns"
                        :placeholder="placeHolderDtmf"
                        default-first-option
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="(item, index) in dsColumnList('dtmf')"
                          :label="item.col_name"
                          :value="item.col_name"
                          :key="index"
                        />
                      </el-select>
                    </el-form-item>
                    <!--              </el-col>-->
                    <!--            </el-row>-->
                    <!--            <el-row type="flex">-->
                    <!--              <el-col :span="6"></el-col>-->
                    <!--              <el-col :span="9">-->
                    <el-form-item prop="grammar" :label="__('Map Grammar')">
                      <el-select
                        v-model="contentForm.grammar"
                        style="width: 100%;"
                        :no-data-text="noDataTextDSColumns"
                        :placeholder="placeHolderGrammar"
                        default-first-option
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="(item, index) in dsColumnList('all')"
                          :label="item.col_name"
                          :value="item.col_name"
                          :key="index"
                        />
                      </el-select>
                    </el-form-item>
                    <!--              </el-col>-->
                    <!--            </el-row>-->
                    <!--            <el-row type="flex">-->
                    <!--              <el-col :span="6"></el-col>-->
                    <!--              <el-col :span="9">-->
                    <el-collapse accordion>
                      <el-collapse-item name="1">
                        <template slot="title">
                          <span
                            style="margin: 30% 10px; font-style: italic; color: #A0A8B5;"
                          >
                            <!-- eslint-disable-next-line -->
                            {{ __("Preview Formtype values from the selected Datastore (mock data)") }}
                          </span>
                        </template>
                        <el-table
                          :data="tableData"
                          height="250"
                          style="width: 100%"
                          class="list-table"
                        >
                          <el-table-column
                            prop="postcode"
                            :label="__('Postcode')"
                            width="180"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="suburb"
                            :label="__('Suburb')"
                            width="180"
                          >
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            :label="__('Store Address')"
                          >
                          </el-table-column>
                        </el-table>
                      </el-collapse-item>
                    </el-collapse>
                    <!--              </el-col>-->
                    <!--            </el-row>-->
                  </el-tab-pane>
                  <el-tab-pane
                    :label="__('Advanced ASR Settings')"
                    name="advanced_asr_settings"
                  >
                    <el-scrollbar :native="false">
                      <div style="max-height: 90vh">
                        <advanced-speech-parameters
                          :speech-complete-timeout="
                            contentForm.speech_complete_timeout
                          "
                          :speech-incomplete-timeout="
                            contentForm.speech_incomplete_timeout
                          "
                          :no-input-timeout="contentForm.no_input_timeout"
                          :speech-start-timeout="
                            contentForm.speech_start_timeout
                          "
                          :inter-result-timeout="
                            contentForm.inter_result_timeout
                          "
                          :barge-in-sensitivity="
                            contentForm.barge_in_sensitivity
                          "
                          :auto-punctuation="contentForm.auto_punctuation"
                          :profanity-filter="contentForm.profanity_filter"
                          :single-utterance="contentForm.single_utterance"
                          @update-nlp-parameter="updateNlpParameters"
                          @handle-advanced-asr-settings-changed="
                            handleAdvancedAsrSettingsChanged
                          "
                        >
                        </advanced-speech-parameters>
                      </div>
                    </el-scrollbar>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px; margin-top: 30px;"
          >
            <save-button
              v-if="!isArchivedStatus"
              :disabled="!contentUpdated || !can('content.custom-forms.write')"
              type="primary"
              @click="submitForm(id)"
              class="submitBtn"
              :primaryKey="id"
              variant="CreateUpdate"
            />
            <el-button @click="handleFormCancel" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import { mapState, mapActions } from "vuex";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import _ from "lodash";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { checkUniqueName } from "@/api/formtypes";
import AdvancedSpeechParameters from "@/components/AdvancedSpeechParameters.vue";
import {
  CHECK_IN_USE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  MOVE_ACTION,
  REFRESH_ACTION,
  RESTORE_ACTION
} from "@/constants/contents";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton,
    AdvancedSpeechParameters
  },
  data() {
    let validateFtName = async (rule, value, callback) => {
      try {
        // update scenario
        if (this.id === 0 && value !== this.contentFormInfo.form_type_name) {
          if (value.length > 100) {
            callback(
              new Error(__("Formtype name cannot exceed 100 characters"))
            );
          }
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(
              new Error(__("This Formtype name is reserved or already exists"))
            );
          } else {
            callback();
          }
        }
        if (this.id === -1) {
          if (value.length > 100) {
            callback(
              new Error(__("Formtype name cannot exceed 100 characters"))
            );
          }
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(
              new Error(__("This Formtype name is reserved or already exists"))
            );
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isChecking = false;
      }
    };

    const validateFtDescription = async (rule, value, callback) => {
      try {
        if (
          (value && value !== this.contentFormInfo.form_type_description) ||
          this.id === -1
        ) {
          if (value.length > 200) {
            callback(new Error(__("Description cannot exceed 200 characters")));
          }
        } else {
          callback();
        }
      } catch (e) {
        this.isChecking = false;
        console.log(e);
      }
    };

    return {
      rules: {
        form_type_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validateFtName, trigger: "blur" }
        ],
        data_store_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Datastore must be selected")
          }
        ],
        dtmf: [
          {
            required: true,
            trigger: "blur",
            message: __("Select a Datastore column for DTMF")
          }
        ],
        form_type_description: [
          { validator: validateFtDescription, trigger: "blur" }
        ]
      },
      isSubmitting: false,
      dscolumns: [],
      dscolumns_integer: [],
      dataStoreFetch: "",
      publishFormTypeStatus: "disable",
      publishFormTypeConfirmed: "disabled",
      noDataTextDSColumns: __("Select a Datastore to view the columns here"),
      noDataTextDS: __("Couldn't find a Datastore in your account"),
      placeHolderDS: __("Please select a Datastore"),
      placeHolderDtmf: __("Please select a DTMF column"),
      placeHolderGrammar: __("Please select a grammar column"),
      tableData: [
        {
          postcode: 3000,
          suburb: "Melbourne",
          address: "530 Collins St, Melbourne"
        },
        {
          postcode: 3001,
          suburb: "Melbourne",
          address: "530 Collins St, Melbourne"
        },
        {
          postcode: 3002,
          suburb: "Melbourne",
          address: "530 Collins St, Melbourne"
        },
        {
          postcode: 3003,
          suburb: "Melbourne",
          address: "530 Collins St, Melbourne"
        },
        {
          postcode: 3004,
          suburb: "Melbourne",
          address: "530 Collins St, Melbourne"
        },
        {
          postcode: 3005,
          suburb: "Melbourne",
          address: "530 Collins St, Melbourne"
        },
        {
          postcode: 3006,
          suburb: "Melbourne",
          address: "530 Collins St, Melbourne"
        }
      ],
      additionalValidateRoute: "custom-forms",
      isChecking: false,
      isContent: true,
      viewMode: "allow_read",
      activeTab: "form",
      advancedAsrSettingsChanged: false,
      forceDeleteEventName: "initiate-force-delete-FormType",
      safeDeleteEventName: "initiate-safe-delete-FormType"
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("dataStores", {
      dataStores: state => state.dataStores,
      dataStoresLoading: state => state.loading
    }),
    ...mapState("canvas", {
      task: state => state.task
    }),
    dsColumnList() {
      return type => {
        let result = [];
        if (this.contentForm.data_store_id) {
          const dataStoresCloned = _.cloneDeep(this.dataStores);
          let filterDataStore = _.filter(dataStoresCloned, {
            data_store_id: this.contentForm.data_store_id
          });
          if (filterDataStore.length > 0) {
            if (type === "dtmf") {
              result = _.filter(
                filterDataStore[0].collection_structure,
                function(chr) {
                  return chr.type === "integer";
                }
              );
            } else {
              result = _.filter(
                filterDataStore[0].collection_structure,
                function(chr) {
                  return chr.type !== "audio";
                }
              );
            }
          }
        }
        return result;
      };
    },
    noIntegerTextDSColumns() {
      if (this.contentForm.data_store_id) {
        return this.dsColumnList
          ? __("Couldn't find any Datastore column of integer type")
          : this.noDataTextDSColumns;
      }
      return this.noDataTextDSColumns;
    },
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    })
  },
  methods: {
    ...mapActions("formTypes", {
      createFormType: "createFormType",
      updateFormType: "updateFormType",
      updateContent: "updateFormType",
      deleteContentMethod: "deleteFormType",
      undoDeleteContent: "undoDeleteFormType",
      safeDeleteContent: "safeDeleteFormType",
      refreshFormType: "refreshFormType"
    }),
    ...mapActions("dataStores", {
      getDataStores: "getDataStores",
      resetDataStores: "resetDataStores"
    }),
    ...mapActions("canvas", {
      getNodes: "getNodes"
    }),
    handleFormCancel() {
      this.handleCancel();
    },
    initializeDataStores() {
      this.dataStoreFetch = this.getDataStores();
    },
    callDataStore() {
      this.$alert(
        // eslint-disable-next-line
        __("Please create a Datastore from the Content menu, if you didn't find one in the dropdown"),
        __("Functionality In Progress"),
        {
          confirmButtonText: "OK",
          callback: () => {
            this.$message({
              type: "info",
              message: __("Thanks for your cooperation")
            });
          }
        }
      );
    },
    onDataStoreChange() {
      this.contentForm.grammar = "";
      this.contentForm.dtmf = "";
    },
    publishFormTypeConfirmation() {
      if (this.contentForm.is_standard) {
        this.publishFormTypeStatus = "publish";
        this.publishFormTypeConfirmed = "published";
      }
      this.$confirm(
        __("Do you want to :status :form_type_name formtype globally", {
          status: this.publishFormTypeStatus,
          form_type_name: this.contentForm.form_type_name
        }),
        __("Warning"),
        {
          confirmButtonText: __("Yes"),
          cancelButtonText: __("No"),
          type: "warning"
        }
      )
        .then(() => {
          this.makeApiCall();
        })
        .catch(() => {
          this.contentForm.is_system = false;
          this.publishFormTypeStatus = "disable";
          this.publishFormTypeConfirmed = "disabled";
          this.$message({
            type: "info",
            message: __("Process canceled")
          });
        });
    },

    makeApiCall() {
      const process = this.updateFormType;
      this.contentForm.ac_id = this.selectedAccountId;
      process(this.contentForm)
        .then(data => {
          this.$message({
            type: "success",
            message: __("Form Type :form_type_name :confirmed successfully", {
              form_type_name: this.contentForm.form_type_name,
              confirmed: this.publishFormTypeConfirmed
            })
          });
          this.publishFormTypeStatus = "disable";
          this.publishFormTypeConfirmed = "disabled";
          EventBus.$emit("list-changed", data.data);
          this.handleFormCancel();
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },

    submitForm(id) {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process = id === -1 ? this.createFormType : this.updateFormType;
          this.contentForm.ac_id = this.selectedAccountId;
          this.contentForm.folder_id = this.selectedFolderId;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              id
                ? this.$message({
                    type: "success",
                    message: __("Custom Formtype added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Custom Formtype updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleFormCancel();
            })
            .then(() => {
              if (this.task && this.advancedAsrSettingsChanged) {
                this.getNodes({
                  ac_id: this.selectedAccountId,
                  task_id: this.task.task_id
                });
              }
            })
            .catch(err => {
              // console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    openMoveToFolderDialog() {
      let content_ids = [this.contentForm.form_type_id];
      if (this.checkedContents.length) {
        content_ids = this.checkedContents.map(item => {
          if (item && item.form_type_id) {
            return item.form_type_id;
          }
        });
      }

      EventBus.$emit("open-move-to-folder-modal", {
        content_ids: [...content_ids],
        content_model: "FormType"
      });
    },

    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "FormType",
        content_id: this.contentForm.form_type_id
      });
    },

    refreshCheckInUse() {
      // call backend api
      EventBus.$emit("call-refresh-check-in-use-job", {
        content_model: "FormType",
        content_id: this.contentForm.form_type_id,
        content_name: this.contentForm.form_type_name,
        refresh: this.refreshFormType
      });
    },

    handleAction(command) {
      switch (command) {
        case EDIT_ACTION:
          this.handleEdit();
          break;
        case MOVE_ACTION:
          this.openMoveToFolderDialog();
          break;
        case CHECK_IN_USE_ACTION:
          this.openCheckInUseDialog();
          break;
        case REFRESH_ACTION:
          this.refreshCheckInUse();
          break;
        case RESTORE_ACTION:
          this.restoreContent(this.contentForm);
          break;
        case DELETE_ACTION:
          this.handleDelete(
            this.contentForm,
            "FormType",
            this.contentForm.form_type_id
          );
          break;
      }
    },
    handleAdvancedAsrSettingsChanged() {
      if (this.id !== -1) {
        this.advancedAsrSettingsChanged = true;
      }
    },
    updateNlpParameters(nlp_param, value, default_value) {
      // console.log("custom form", nlp_param, value);
      if (value !== false && value !== 0 && !value) {
        // console.log("updating to default");
        value = default_value;
      }
      this.$set(this.contentForm, nlp_param, value);
    }
  },
  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });

    EventBus.$on(this.safeDeleteEventName, ({ content }) => {
      this.initiateSafeDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
    EventBus.$off(this.safeDeleteEventName);
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.custom-formtype-icon {
  height: 40px;
  width: 50px;
  background-size: cover;
  padding-top: 5px;
  margin-right: 10px;
  margin-top: 32px;
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
